import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/media'
import { Header, Layout, SubHeader } from 'components/global'
import LegalBody from 'components/shared/legalBody'

const PromotionBody = styled.section`
  position: relative;
  overflow-x: hidden;
  margin: 0 auto;
  background-color: var(--offWhite);
  section {
    width: 90%;
    padding: 0 0 2rem 0;
    margin: var(--auto);
    color: var(--darkGrey);
    max-width: 1000px;
    p,
    ul,
    strong {
      font-size: 1rem;
    }
    a {
      color: var(--purple);
      font-weight: 600;
    }
    h3 {
      color: var(--purple);
      font-size: 1.95rem;
    }
    p {
      margin: 0.5rem 0;
    }
    .img {
      text-align: center;

      width: 90%;
      svg {
        max-width: 500px;
        margin: 2rem 0;
      }
    }
    div {
      margin-top: 1rem;
      h4 {
        color: var(--purple);
        font-size: 1.05rem;
      }
    }

    .spacer {
      display: grid;
      grid-template-columns: 30% 1fr;
      grid-gap: 2rem;
      @media ${media.md} {
        grid-template-columns: 23% 1fr;
      }
      strong,
      p {
        margin: 0;
      }
      li {
        margin: 0.5rem 0;
      }
      ol {
        padding-left: 25px;
        li {
          font-weight: 500;
        }
      }
    }
    .long {
      grid-template-columns: 1fr;
      grid-gap: 0.25rem;
      @media ${media.md} {
        grid-template-columns: 20% 1fr;
      }
      ul {
        width: 90%;
        margin: 0 auto;
        &:nth-child(1) {
          li {
            margin-top: 0;
          }
        }
      }
    }
    .tc {
      margin-top: 1.5rem;
      li {
        margin: 0.5rem 0;
      }
    }
  }
`

const TNLpromotion = () => {
  const meta = {
    title: 'Instagram Givewaway | Arnold Clark Cup',
  }
  return (
    <Layout title={meta.title}>
      <SubHeader title="Ticket Giveaway" />
      <LegalBody>
        <ol>
          <li>
            The following terms and conditions (the &ldquo;
            <strong>Terms and Conditions</strong>&rdquo;) apply to The Arnold
            Clark Cup 2023 Ticket Giveaway (the &ldquo;
            <strong>Competition</strong>&rdquo;) which will run from 24
            <sup>nd</sup> November to 1<sup>st</sup> December 2022.
          </li>
          <li>
            The promoter of the Competition is Pitch International LLP (&ldquo;
            <strong>Pitch</strong>&rdquo;, &ldquo;<strong>we</strong>&rdquo;,
            &ldquo;<strong>us</strong>&rdquo;, &ldquo;<strong>our</strong>
            &rdquo;) of 27 Brewhouse Lane, Putney Wharf, London, SW15 2JX.
          </li>
          <li>
            By entering this Competition, you confirm your agreement to be bound
            by these Terms and Conditions.
          </li>
          <p>
            <strong>Who can enter?</strong>
          </p>
          <li>
            Entry to the Competition is open to anyone based in the United
            Kingdom, and if aged 18 or under you must have permission from an
            adult to enter.
          </li>
          <li>
            Employees of Pitch and members of their immediate families, or
            anyone connected to or involved in the administration of the
            Competition, are not permitted to enter.
          </li>

          <p>
            <strong>How do I enter?</strong>
          </p>

          <li>
            To enter the Competition, individuals must follow @arnoldclarkcup on
            Instagram, tag a friend in the comments of the competition post and
            share the competition post on their personal Instagram story. No
            payment or purchase is necessary to enter the Competition.
          </li>
          <li>
            Only entries received by 6pm (GMT) on 1<sup>st</sup> December 2022
            (the &ldquo;<strong>Closing Date</strong>&rdquo;) will be entered
            into the Competition. Entries received after this Closing Date will
            not be processed.
          </li>
          <li>There can be a maximum of one entry per entrant.</li>
          <li>
            Pitch does not accept responsibility for any entries which are lost,
            delayed, incomplete or otherwise not received or cannot be entered
            for technical or any other reason. Proof of delivery of the entry is
            not proof of receipt. Incomplete or illegible entries or those not
            in accordance with the entry instructions and these Terms and
            Conditions are invalid.
          </li>
          <p>
            <strong>How do I win?</strong>
          </p>
          <li>
            The Competition winners will be selected at random from all valid
            entries received by the Closing Date.
          </li>
          <li>
            The Competition winners will be notified by Instagram direct message
            on or before 1<sup>st</sup> December 2022. The Competition winners
            will be asked to provide certain information in accordance with
            these Terms and Conditions (please refer to clauses 13 and 19
            below). Upon providing such information, the Competition winners
            will, in turn, be provided with instructions on how to redeem their
            prize.
          </li>
          <li>
            All reasonable efforts will be made to contact the Competition
            winners. In the event that: (a) Pitch is unable to make contact with
            the Competition winners on or before 5<sup>th</sup> December 2022;
            (b) the Competition winners refuse or for whatever reason cannot
            accept the prize; or (c) Pitch becomes aware that the Competition
            winner&rsquo;s entry was not in accordance with these Terms and
            Conditions, Pitch reserves the right to withdraw the prizes and
            allocate it to the next eligible entrant drawn at random.
          </li>
          <p>
            <strong>What is the prize?</strong>
          </p>
          <li>
            There will be two prizes for the Competition. Each prize shall
            consist of two (2) complimentary tickets to one match day of the
            winner&rsquo;s choice at the Arnold Clark Cup 2023.
          </li>
          <li>
            The prizes are non-exchangeable, non-transferable, and not
            redeemable for cash or other prizes (in whole or in part). In the
            event of unforeseen circumstances or circumstances outside of
            Pitch&rsquo;s control, Pitch reserves the right to offer an
            alternative prize of equal or greater value. The Competition winner
            is strictly prohibited from selling their prizes to any other
            person.
          </li>
          <li>
            Each prize consists solely of two (2) tickets and shall not include
            any travel, accommodation or other costs in relation to the
            winner&rsquo;s attendance at the match day.
          </li>
          <p>
            <strong>Publicity </strong>
          </p>
          <li>
            By submitting an entry to this Competition you agree, as a potential
            Competition winner, to participate in reasonable publicity relating
            to the Competition including but not limited to the publication of
            your Instagram handle and county of residence on the Arnold Clark
            Cup Instagram page and/or on other marketing communications, unless
            you notify us in writing that you wish to retain your anonymity in
            which event we will seek to limit the availability of your
            information to the amount which is required for the purposes of our
            compliance with applicable advertising laws.
          </li>
          <li>
            No fees shall be payable to any entrant in relation to the use of
            any such publicity materials.
          </li>
          <p>
            <strong>Data protection</strong>
          </p>

          <li>
            One Competition winner will be asked to provide certain personal
            information including their full name, contact address(es) and
            contact telephone number. By doing so, you agree that Pitch shall be
            entitled to process such personal information for the purposes of
            the administration of the Competition and in accordance with our
            Privacy Policy (which can be found on The Arnold Clark Cup website).
          </li>
          <li>
            If the required personal information is not provided by a selected
            Competition winner within a reasonable period of time following
            Pitch&rsquo;s request for the same, Pitch reserves the right to
            withdraw the prize and allocate it to the next eligible entrant
            drawn at random.
          </li>
          <p>
            <strong>Decisions final</strong>
          </p>
          <li>
            All decisions made by Pitch relating to the Competition and/or
            redemption of the prizes are final, and no correspondence will be
            entered into.
          </li>
          <p>
            <strong>General</strong>
          </p>

          <li>
            Pitch accepts no responsibility for any damage, loss, liabilities,
            injury or disappointment incurred or suffered by you as a result of
            entering the Competition, not being able to enter into the
            Competition or accepting and/or using the prizes. Pitch further
            disclaims liability for any injury or damage to your or any other
            person's computer or IT systems relating to or resulting from
            participation in or downloading of any materials in connection with
            the Competition. Nothing shall exclude the liability of Pitch for
            fraud or for death or personal injury caused by our negligence or
            for any other liability that cannot be excluded by law.
          </li>
          <li>
            Pitch reserves the right at any time and from time to time to modify
            or discontinue, temporarily or permanently, this Competition with or
            without prior notice due to reasons outside of our control or to
            amend or modify these Terms and Conditions at any time.
          </li>
          <li>
            If any part of these Terms and Conditions is found to be illegal,
            invalid or otherwise unenforceable by a court or regulator, then
            that part shall be severed and deleted from these Terms and
            Conditions and the remaining Terms and Conditions shall survive and
            remain in full force and effect.
          </li>
          <p>
            <strong>Governing laws</strong>
          </p>

          <li>
            These Terms and Conditions, including their subject matter and
            formation, are governed by the laws of England and Wales. Any
            disputes arising under or in connection with them shall be subject
            to the exclusive jurisdiction of the courts of England and Wales.
          </li>
        </ol>
      </LegalBody>
    </Layout>
  )
}

export default TNLpromotion
